import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../../assets/icons/icons"
import {accordionData, pricingTableData, socialViews} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-instagram-views.png";
import {Helmet} from "react-helmet";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 12;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    let accordionData1 = accordionData.filter((x) => x.id < 5);
    let accordionData2 = accordionData.filter((x) => x.id > 4)
    return (
        <>
            <Helmet>
                <title>Buy instagram Views - Instant delivery - 100% Real </title>
                <meta name="description" content="Get real IG views from IGChamp from just $1. Real and Instant likes, best place to buy real likes with Instant delivery."/>
                <meta property="og:title" content="Buy instagram Views - Instant delivery - 100% Real "/>
                <meta property="og:description" content="Get real IG views from IGChamp from just $1. Real and Instant likes, best place to buy real likes with Instant delivery."/>
                <meta name="keywords" content="Buy Instagram Views"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-instagram-views"/>
                <link rel="canonical" href="https://igchamp.com/buy-instagram-views"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-3xl leading-normal font-semibold">Buy Instagram Views to Increase Your
                            Visibility</h1>
                        <p>Buy Instagram Views to increase your visibility with IGChamp. More views mean more exposure,
                            so attract a larger audience and boost your engagement. It’s time to get noticed and grow
                            your Instagram presence effortlessly instantly. </p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600">
                            <Link>Buy Instagram Views</Link></li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div key={index}
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link} rel="nofollow">Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy Instagram Views"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h3 className="mb-4 text-2xl leading-normal font-medium">Get Noticed with Real Instagram
                            Views</h3>
                        <p className="text-slate-400">Get noticed with real Instagram views from IGChamp. With us, you
                            can increase your visibility and effortlessly attract a wider audience. Our service delivers
                            genuine engagement, enhancing your profile’s credibility and reach. Also, this gives you a
                            way to boost your presence on one of the most popular social platforms with ease. Whether
                            you’re a business looking to expand your customer base or an influencer aiming to grow your
                            influence, our tailored solutions cater to your specific needs. Trust IGChamp to help you
                            achieve your Instagram goals effectively and securely. You can start right away to see the
                            difference real views can make to your online presence.</p>
                        <div className="mt-4">
                            <Link to="/buy-instagram-followers"
                                  className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">
                                Click here to boost you Instagram
                                <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="w-full table relative ">

                <div className="container relative mt-16 mb-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why You
                            Should Buy From IGChamp?</h3>
                    </div>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[24px]">
                        {socialViews.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index}
                                     className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                    <Icons className="h-10 w-10 stroke-1 text-indigo-600"/>

                                    <div className="content mt-6">
                                        <h5
                                            className="title h5 text-xl font-semibold hover:text-indigo-600">{item.title}</h5>
                                        <p className="text-slate-400 mt-4">{item.desc}</p>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            An Affordable Package</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Choose an affordable package that fits your
                            budget with IGChamp. We offer a range of options designed to meet your needs. With us, you
                            can enjoy high-quality Instagram views at competitive prices. So, boost your visibility and
                            engagement effortlessly to start growing your Instagram presence today.</p>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Select Package</h5>
                                        <p className="mt-3 mb-0 text-slate-400">We offer a variety of packages to fit
                                            your needs and budget. Choose the one that suits you best and start buying
                                            real Instagram views. Each package brings engaged activity to your account
                                            to meet your goals, expectations, and needs. With IGChamp, this is just the
                                            start of your social media success.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Put Desired Info</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Next, you’ll need to fill in the
                                            necessary details to help us move forward efficiently. We will ask for your
                                            Instagram handle and any specific preferences you may have regarding the
                                            service. Rest assured, your privacy is our priority. We won’t request your
                                            passwords or any sensitive data. By providing this information, you’ll
                                            enable us to deliver the best possible results tailored to your needs.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Pay for your order</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Once you’ve provided all the required
                                            information, you’ll need to make the payment. You can securely complete your
                                            purchase using our trusted payment options. We prioritize your convenience
                                            and security, offering various payment methods to suit your needs. This
                                            ensures you can confidently buy real Instagram views with transparent
                                            pricing and no hidden fees.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Order Delivered</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Lastly, simply sit back and watch your
                                            content’s engagement grow. Our professional and dedicated service ensures
                                            quick results, boosting your visibility and attracting your audience. If you
                                            have any questions, we’re always here to help. With IGChamp, your
                                            satisfaction is guaranteed every step of the way.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently
                                            Asked Questions!</h3>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why should I buy real Instagram views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying views
                                                            can significantly boost your visibility and engagement on
                                                            Instagram. Besides this, it enhances your credibility by
                                                            making your content appear more popular and attractive to
                                                            potential followers. This increased visibility can lead to
                                                            more organic views and engagement, as Instagram algorithms
                                                            often promote content that already has high
                                                            interaction. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Moreover,
                                                            buying views is a strategic way to jumpstart your growth,
                                                            especially if you’re just starting or aiming to reach a
                                                            wider audience quickly. IGChamp ensures that the views you
                                                            purchase are from real accounts, providing a solid
                                                            foundation for building your Instagram presence effectively
                                                            and efficiently.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can you deliver it right when I upload content?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, at IG
                                                            Champ, we offer options for automatic delivery soon after
                                                            you upload your content. This feature ensures that your
                                                            posts receive views promptly, enhancing their visibility
                                                            right from the moment they go live. Our system is designed
                                                            to provide timely support. Eventually, it allows maximum
                                                            engagement and attract attention to your posts
                                                            immediately.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can buying views get my account banned?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">No, buying
                                                            views from IGChamp will not get your account banned. We
                                                            adhere strictly to Instagram’s terms of service and
                                                            guidelines. Besides this, we ensure that all views provided
                                                            are from real users and generated organically. Our methods
                                                            are safe and compliant with Instagram’s policies,
                                                            prioritizing the security and integrity of your account. You
                                                            can confidently use our services to boost your visibility on
                                                            Instagram without risking any penalties or restrictions from
                                                            the platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What is the quality of your views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">At IGChamp, we
                                                            provide high-quality views that are genuine and from real
                                                            Instagram users. Our views contribute to organic engagement
                                                            on your posts. This will enhance your visibility and
                                                            credibility on the platform. We prioritize delivering views
                                                            that are active and relevant to your content, ensuring that
                                                            each interaction contributes positively to your overall
                                                            Instagram strategy. With our services, you can expect
                                                            consistent and reliable quality that supports your goals of
                                                            effectively increasing reach and engagement.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What do I need to provide you when buying your services?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">When buying our
                                                            services at IGChamp, you will need to provide us with your
                                                            Instagram username (handle) and specify which posts or
                                                            content you want to boost with views. We prioritize your
                                                            privacy and security, so we do not require your Instagram
                                                            password or any other sensitive information. Simply provide
                                                            the necessary details to complete your order, and we’ll
                                                            handle the rest efficiently to ensure you receive the views
                                                            you’ve purchased promptly and securely.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How fast will I see the results?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">You can expect
                                                            to see results shortly after purchasing views from IGChamp.
                                                            Typically, views will start appearing on your selected posts
                                                            within a short timeframe, often within a few minutes to
                                                            hours. All of this depends on the package and your specific
                                                            requirements. Our goal is to deliver prompt results to
                                                            enhance your visibility and engagement on Instagram quickly.
                                                            This rapid increase in views can help attract more attention
                                                            to your content and potentially boost organic engagement as
                                                            well.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I trust IGChamp?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Definitely! You
                                                            can trust IGChamp to provide reliable and effective
                                                            services for buying Instagram views. We prioritize
                                                            transparency, security, and customer satisfaction. Our
                                                            methods ensure that views are from real users, complying
                                                            with Instagram’s guidelines to maintain the integrity of
                                                            your account. We have a proven track record of delivering
                                                            quality results and have helped numerous clients enhance
                                                            their Instagram presence successfully. Rest assured, when
                                                            you choose IGChamp, you’re opting for a trustworthy partner
                                                            committed to helping you achieve your social media goals
                                                            safely and efficiently.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Is it safe to purchase Instagram views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, it is safe
                                                            to purchase Instagram views from IGChamp. We prioritize
                                                            safety and compliance with Instagram’s terms of service. Our
                                                            views come from real and active accounts, ensuring
                                                            authenticity and reducing the risk associated with
                                                            artificial engagement. IGChamp uses secure methods that do
                                                            not violate Instagram’s policies, safeguarding your account
                                                            from any penalties or restrictions. Getting Instagram views
                                                            from IGChamp is a strategic way to boost your visibility
                                                            and credibility on Instagram without compromising the
                                                            integrity of your account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Are the Instagram Views real and from active users?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, the
                                                            Instagram views provided by IGChamp are real and come from
                                                            active users. We prioritize delivering genuine engagement
                                                            that enhances your visibility and credibility on the
                                                            platform. Our views are sourced organically, ensuring that
                                                            each interaction contributes positively to your Instagram
                                                            profile’s growth. You can trust that the views you purchase
                                                            are from real users who are genuinely interested in your
                                                            content, helping you to build a more engaged audience over
                                                            time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I buy Instagram Views for multiple posts?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">At IGChamp,
                                                            you have the flexibility to purchase Instagram views for
                                                            multiple posts. Whether you have several posts you want to
                                                            boost simultaneously or over a period, our service allows
                                                            you to select multiple posts for view enhancement. This
                                                            flexibility enables you to manage your Instagram strategy
                                                            effectively, targeting different content pieces to increase
                                                            visibility and engagement across your profile.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div key="21"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(21)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 21 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Are there any hidden fees when I buy Instagram Views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 21 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 21 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">No, there are no hidden fees when you buy Instagram Views from IGChamp. We believe in transparency and clarity in our pricing structure. The price you see for each package is the total cost you’ll pay, with no additional or hidden charges. Our goal is to provide straightforward and reliable services that help you achieve your Instagram goals without any surprises. Rest assured, when you purchase views from IGChamp, you can confidently plan your budget, knowing exactly what you’ll be paying.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div key="22"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(22)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 22 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How long do the Instagram views stay on my posts?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 22 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 22 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">The Instagram views provided by IGChamp are permanent and will stay on your posts indefinitely. Once we deliver the views to your selected posts, they become a part of your overall view count and contribute to your content’s visibility and engagement metrics. These views do not disappear over time; they remain as part of your post’s analytics and can continue to attract attention and engagement from your audience. This ensures that the impact of buying views lasts beyond the initial delivery, supporting your Instagram growth strategy effectively.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div key="23"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(23)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 23 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Do I need to provide my Instagram password to buy views?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 23 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 23 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">No, you do not need to provide your Instagram password when you buy views from IGChamp. We prioritize the security and privacy of our customers, and we do not require sensitive information such as passwords. All we need is your Instagram username (handle) and details about the posts you want to boost with views. By ensuring that we do not request passwords, we maintain a secure and trustworthy service for purchasing Instagram views, allowing you to enhance your profile safely and effectively.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}
