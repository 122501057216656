import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../../assets/icons/icons"
import { pricingTableData, socialPresence} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-instagram-followers.png";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 10;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    const ldJson=
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://igchamp.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Buy Instagram Followers",
                    "item": "https://igchamp.com/buy-instagram-followers"
                }
            ]
        };
    let reviewsJson =
            {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "Buy Instagram Followers",
                "brand": {
                "@type": "Brand",
                "name": "IGChamp"
            },
                "sku": "buy-instagram-followers",
                "mpn": "buy-instagram-followers",
                "offers": {
                "@type": "AggregateOffer",
                "url": "https://igchamp.com/buy-instagram-followers",
                "priceCurrency": "USD",
                "lowPrice": "2.69",
                "highPrice": "119",
                "offerCount": "8"
            },
                "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "2196",
                "reviewCount": "2196"
            },
                "review": [{
                "@type": "Review",
                "name": "Real Followers",
                "reviewBody": "I've seen tremendous growth in my follower count since using IG Champ. The service is reliable, and the results are real. Highly recommended!",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-06-26",
                "author": {"@type": "Person", "name": "Sarah"}
            },{
                "@type": "Review",
                "name": "Instant Delivery",
                "reviewBody": "Fantastic service! IG Champ helped me reach a broader audience and significantly boosted my profile. I couldn't be happier with the results.",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-08-07",
                "author": {"@type": "Person", "name": "James"}
            },{
                "@type": "Review",
                "name": "Great value for money",
                "reviewBody": "I was skeptical at first, but IG Champ delivered exactly what they promised. My follower count and engagement rates have soared. Great value for money.",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-08-12",
                "author": {"@type": "Person", "name": "Oliver"}
            },{
                "@type": "Review",
                "name": "IGChamp is exceptional",
                "reviewBody": "IGChamp is exceptional! The service was easy to navigate, and I’m thrilled with how my account has grown. My music posts are now reaching a wider audience. Perfect for musicians!",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-08-19",
                "author": {"@type": "Person", "name": "Mike"}
            },{
                "@type": "Review",
                "name": "Delivered as promised",
                "reviewBody": "I had a solid experience with IGChamp. The number of followers I received was as promised, and there was a notable improvement in my reach.",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-09-03",
                "author": {"@type": "Person", "name": "Lisa"}
            },{
                "@type": "Review",
                "name": "Highly recommended",
                "reviewBody": "Unbelievable results! My follower count skyrocketed, and engagement doubled after using IGChamp. My art is finally getting the recognition it deserves. Highly recommend for artists!",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-09-05",
                "author": {"@type": "Person", "name": "Alice"}
            },{
                "@type": "Review",
                "name": "Quality of ig followers",
                "reviewBody": "So impressed with IGChamp! The quality of followers was amazing, and they actually engage with my content. Worth every penny for any influencer wanting to grow a brand!",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1"
            },
                "datePublished": "2024-09-12",
                "author": {"@type": "Person", "name": "Jake"}
            }]
            };
    const faqJson={
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How to Buy Instagram Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Purchasing Instagram followers with IG Champ is simple. Start by visiting our website and navigating the 'Buy Instagram Followers' section. From there, select the package that best suits your needs and budget. Next, provide us with your Instagram username and any specific preferences you have regarding your followers, such as targeting a particular audience or demographic. Once you've filled in the necessary information, proceed to the checkout page to complete your purchase securely. After your payment is processed, our team will begin working on delivering your followers promptly. Sit back, relax, and watch as your Instagram following grows with real, engaged users from IG Champ."
            }
        },{
            "@type": "Question",
            "name": "Where to Buy Instagram Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "When it comes to buying Instagram followers, it's essential to choose a reputable and trustworthy provider like IG Champ. With a proven track record of delivering high-quality followers and excellent customer service, IG Champ stands out as a top choice for anyone looking to boost their Instagram presence. Unlike unreliable providers that may offer low-quality followers or use unethical tactics, IG Champ prioritizes authenticity and transparency. You can buy Instagram followers confidently from IG Champ, knowing you're investing in genuine growth that aligns with Instagram's guidelines."
            }
        },{
            "@type": "Question",
            "name": "How to Pay for Instagram Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Paying for Instagram followers with IG Champ is convenient and secure. Once you've selected your desired follower package, proceed to the checkout page, where various payment options are available. We accept major credit cards, debit cards, and other secure online payment methods to ensure a hassle-free transaction. Choose your preferred payment method, enter your payment details, and follow the prompts to complete your purchase securely. Rest assured that your payment information is encrypted and protected, providing peace of mind. After your payment is confirmed, our team will deliver your followers promptly, helping you grow your Instagram presence effectively."
            }
        },{
            "@type": "Question",
            "name": "Why Do Brands Choose IG Champ?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Brands choose IG Champ for one simple reason: we deliver results that exceed expectations. Our commitment to excellence and our proven track record of success sets us apart as the go-to choice for Instagram growth. With IG Champ, brands can trust that they're getting more than just followers—they're gaining a strategic partner dedicated to their success."
            }
        },{
            "@type": "Question",
            "name": "Why Should I Buy Instagram Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Buying Instagram followers can offer several benefits for individuals and businesses looking to boost their presence on the platform. Here are some reasons why you might consider buying Instagram followers: Instant Credibility: A higher follower count can give your profile instant credibility, making it appear more reputable and trustworthy to potential followers and customers. Increased Visibility: With more followers, your posts are more likely to appear in other users' feeds, increasing your visibility and reach on the platform. Social Proof: A large following can serve as social proof of your popularity and influence, organically attracting more followers and engagement. Brand Growth: For businesses, a larger following can lead to increased brand awareness, customer trust, and, ultimately, sales and conversions. Kickstart Growth: Buying followers can kickstart your Instagram growth, providing a solid foundation you can continue to build organically over time."
            }
        },{
            "@type": "Question",
            "name": "How Many Followers Can You Buy At Once?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "With IG Champ, you can purchase up to 25,000 followers at once. This allows you to quickly and effectively boost your Instagram presence with a significant number of followers, helping you increase your visibility and credibility on the platform."
            }
        },{
            "@type": "Question",
            "name": "Can I Buy Instagram Followers for My Business?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely! Buying Instagram followers for your business can be a strategic move to enhance your brand's visibility, credibility, and reach. A larger follower count can attract more attention to your business profile, making it appear more reputable and trustworthy to potential customers. Besides this, a higher follower count can increase engagement with your content, ultimately driving more traffic to your website, generating leads, and increasing sales. However, it's essential to ensure that you're purchasing followers from a reputable provider like IG Champ to guarantee high-quality, real followers who authentically engage with your content. Additionally, buying followers should be just one part of your overall Instagram marketing strategy, including creating compelling content, engaging with your audience, and using other growth tactics to build a genuine and active community around your brand."
            }
        },{
            "@type": "Question",
            "name": "Could My Account Be Banned From Buying Instagram Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Buying Instagram followers is not against Instagram's terms of service to buy followers, but it's important to be cautious and use reputable providers like IG Champ. Instagram has algorithms to detect and remove fake or bot accounts. So, if you purchase followers from low-quality or unethical sources, there's a risk that those followers could be detected and removed, potentially resulting in penalties for your account. To minimize the risk of your account being banned or penalized, buying followers from a trusted provider that delivers high-quality, real followers who engage with your content authentically is better. IG Champ prioritizes authenticity and transparency, ensuring that all followers provided are genuine and compliant with Instagram's guidelines. It's also important to complement your follower growth strategy with other organic growth tactics, such as creating high-quality content, engaging with your audience, and participating in community activities on the platform. By taking a balanced approach to growing your Instagram presence, you can mitigate the risk of penalties and ensure long-term success for your account."
            }
        },{
            "@type": "Question",
            "name": "Will Anyone Know I Have Purchased Followers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "When you purchase followers from a reputable provider like IG Champ, it's unlikely that anyone will know you've bought followers unless you explicitly disclose it. However, if you buy followers from low-quality or unethical sources, it may become apparent to others. Fake followers typically lack engagement, have incomplete profiles, or exhibit suspicious behavior, which can be easily identified by savvy Instagram users or by Instagram's algorithms. This could potentially damage your reputation and credibility on the platform. To ensure that your purchased followers blend seamlessly with your organic following and appear genuine, it is crucial to choose a trusted provider that delivers high-quality, real followers who engage with your content authentically. By investing in authentic growth, you can maintain the integrity of your account and avoid any negative consequences associated with buying followers from unreliable sources."
            }
        },{
            "@type": "Question",
            "name": "How Fast Will I See Results?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Once you hit that purchase button with IG Champ, your Instagram presence is set to skyrocket! From the moment your order is confirmed, our team kicks into high gear, working tirelessly behind the scenes to increase your follower count. Within hours, your follower numbers will grow, transforming your profile with real activity and engagement. With IG Champ, the results are not just fast; they're electrifying!"
            }
        }]
    };
    return (
        <>
            <Helmet>
                <title>Buy instagram followers - Real and Instant - Starting from $2</title>
                <meta name="description"
                      content="Buy Instagram followers from IGChamp from just $2. Real and Instant followers, 24/7 customer support with satisfaction guarentee since 2012."/>
                <meta property="og:title" content="Buy instagram followers - Real and Instant - Starting from $2"/>
                <meta property="og:description"
                      content="Buy Instagram followers from IGChamp from just $2. Real and Instant followers, 24/7 customer support with satisfaction guarentee since 2012."/>
                <meta name="keywords"
                      content="Buy Instagram followers, Buy ig followers, purchase instagram followers, buy followers instagram, real instagram followers, buying instagram followers, buy insta followers"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-instagram-followers"/>
                <link rel="canonical" href="https://igchamp.com/buy-instagram-followers"/>
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
            <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <script type="application/ld+json">
                            {JSON.stringify(reviewsJson)}
                        </script>

                        <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline text-red-600">2K+ Customer rate us 5 stars rating.</li>
                        </ul>
                        <h1 className="text-3xl leading-normal font-semibold">Buy Instagram Followers with Instant
                            Delivery</h1>
                        <p>Boost your Instagram presence with IG Champ – your trusted partner for buying real Instagram
                            followers
                            at affordable prices.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600">
                            <Link to="/buy-instagram-followers">Buy Instagram Followers</Link></li>

                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div key={index}
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link} rel="nofollow">Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy Instagram Followers" title="Buy Instagram Followers"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h3 className="mb-4 text-2xl leading-normal font-medium">Buy Active Instagram Followers for
                            Unmatched Social Presence</h3>
                        <p className="text-slate-400">When you buy genuine Instagram followers from IG Champ, you invest
                            in real people who actively engage with your content. Avoid the pitfalls of bot accounts
                            that can jeopardize your profile with fake followers and low interaction rates. Our
                            authentic followers boost your credibility, ensuring genuine engagement that enhances your
                            social presence. With IG Champ, you get quality followers who contribute to your growth and
                            help you achieve an engaging Instagram community.</p>
                    </div>
                </div>
            </section>
            <section
                className="w-full table relative ">

                <div className="container relative mt-16 mb-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Are You
                            Ready to buy Instagram followers?</h3>
                    </div>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[24px]">
                        {socialPresence.map((item, index) => {
                            let Icons = item.icon
                            return (
                                <div key={index}
                                     className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                    <Icons className="h-10 w-10 stroke-1 text-indigo-600"/>

                                    <div className="content mt-6">
                                        <Link to="#"
                                              className="title h5 text-xl font-semibold hover:text-indigo-600">{item.title}</Link>
                                        <p className="text-slate-400 mt-4">{item.desc}</p>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">How to
                            Buy From IG Champ</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">At IG Champ, we understand the importance of
                            flexibility and affordability. We offer a range of Instagram follower packages designed to
                            fit any budget. Our packages are tailored to your needs and provide excellent value for
                            money.
                            Find the perfect plan to meet your needs to kickstart your journey.</p>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className=" md:ms-auto" alt="Select Package"/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Select Package</h5>
                                        <p className="mt-3 mb-0 text-slate-400">By real followers by choosing from our
                                            diverse range of follower packages at IG Champ, tailored to suit your
                                            budget, goals, and expectations. Whether you're a small business,
                                            influencer, or just starting, you'll find the perfect plan for your needs.
                                            Each package is designed to deliver real, engaged followers to help you grow
                                            your Instagram presence. Pick the one that best aligns with your goals, and
                                            watch your follower count soar.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className=" md:me-auto" alt="Put Desired Info"/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Put Desired Info</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Fill in the required information to help
                                            us deliver your followers efficiently and accurately. You'll need to provide
                                            your Instagram handle and any specific preferences or targets you have in
                                            mind. Rest assured, IG Champ values your privacy and never asks for your
                                            password or sensitive data. Our streamlined process ensures a smooth
                                            experience from start to finish.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt="Pay for your order"/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Pay for your order</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Complete your purchase securely using
                                            our trusted payment options. At IG Champ, we prioritize your security and
                                            convenience, offering multiple payment methods to suit your needs. Once your
                                            payment is processed, our team will get to work on delivering your
                                            followers. With transparent pricing and no hidden fees, you can confidently
                                            buy, knowing exactly what you're getting.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className=" md:me-auto" alt="Order Delivered"/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Order Delivered</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Watch your Instagram following grow as
                                            real when active users engage with your content. IG Champ's efficient
                                            service ensures you see results quickly, helping boost your visibility and
                                            get noticed by your targeted audience. Our dedicated support team is always
                                            on hand to assist with any questions or concerns, ensuring your satisfaction
                                            at every step.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently
                                            Asked Questions!</h3>
                                        <script type="application/ld+json">
                                            {JSON.stringify(faqJson)}
                                        </script>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How to Buy Instagram Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Purchasing
                                                            Instagram followers with IG Champ is simple. Start by
                                                            visiting our website and navigating the 'Buy Instagram
                                                            Followers' section. From there, select the package that
                                                            best suits your needs and budget. Next, provide us with
                                                            your Instagram username and any specific preferences you
                                                            have regarding your followers, such as targeting a
                                                            particular audience or demographic.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Once you've
                                                            filled in the necessary information, proceed to the
                                                            checkout page to complete your purchase securely. After
                                                            your payment is processed, our team will begin working
                                                            on delivering your followers promptly. Sit back, relax,
                                                            and watch as your Instagram following grows with real,
                                                            engaged users from IG Champ.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Where to Buy Instagram Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">When it
                                                            comes to buying Instagram followers, it's essential to
                                                            choose a reputable and trustworthy provider like IG
                                                            Champ. With a proven track record of delivering
                                                            high-quality followers and excellent customer service,
                                                            IG Champ stands out as a top choice for anyone looking
                                                            to boost their Instagram presence. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Unlike
                                                            unreliable providers that may offer low-quality
                                                            followers or use unethical tactics, IG Champ prioritizes
                                                            authenticity and transparency. You can buy Instagram
                                                            followers confidently from IG Champ, knowing you're
                                                            investing in genuine growth that aligns with Instagram's
                                                            guidelines.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How to Pay for Instagram Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Paying for
                                                            Instagram followers with IG Champ is convenient and
                                                            secure. Once you've selected your desired follower
                                                            package, proceed to the checkout page, where various
                                                            payment options are available. We accept major credit
                                                            cards, debit cards, and other secure online payment
                                                            methods to ensure a hassle-free transaction.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Choose your
                                                            preferred payment method, enter your payment details,
                                                            and follow the prompts to complete your purchase
                                                            securely. Rest assured that your payment information is
                                                            encrypted and protected, providing peace of mind. After
                                                            your payment is confirmed, our team will deliver your
                                                            followers promptly, helping you grow your Instagram
                                                            presence effectively.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why Do Brands Choose IG Champ?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Brands
                                                            choose IG Champ for one simple reason: we deliver
                                                            results that exceed expectations. Our commitment to
                                                            excellence and our proven track record of success sets
                                                            us apart as the go-to choice for Instagram growth. With
                                                            IG Champ, brands can trust that they're getting more
                                                            than just followers—they're gaining a strategic partner
                                                            dedicated to their success.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Here's why
                                                            brands love IG Champ:</p>
                                                    </div>
                                                    <ul className="list-none space-x-1 text-slate-400 mt-3">
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Quality
                                                            Assurance: We pride ourselves on delivering
                                                            high-quality, real followers who engage authentically
                                                            with your content. Our rigorous screening process
                                                            ensures that every follower you receive is genuine and
                                                            aligned with your target audience.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Transparency:
                                                            We believe in transparency every step of the way. From
                                                            our pricing structure to our follower acquisition
                                                            methods, we're upfront and honest about how we operate.
                                                            With IG Champ, you'll always know exactly what you're
                                                            getting.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Proven
                                                            Results: Don't just take our word for it—our track
                                                            record speaks for itself. Countless brands have
                                                            experienced significant growth and success with IG
                                                            Champ, making us the trusted choice for Instagram
                                                            growth.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why Should I Buy Instagram Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying
                                                            Instagram followers can offer several benefits for
                                                            individuals and businesses looking to boost their
                                                            presence on the platform. Here are some reasons why you
                                                            might consider buying Instagram followers:</p>
                                                    </div>
                                                    <ul className="list-none space-x-1 text-slate-400 mt-3">
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Instant
                                                            Credibility: A higher follower count can give your
                                                            profile instant credibility, making it appear more
                                                            reputable and trustworthy to potential followers and
                                                            customers.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Increased
                                                            Visibility: With more followers, your posts are more
                                                            likely to appear in other users' feeds, increasing your
                                                            visibility and reach on the platform.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Social
                                                            Proof: A large following can serve as social proof of
                                                            your popularity and influence, organically attracting
                                                            more followers and engagement.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Brand
                                                            Growth: For businesses, a larger following can lead to
                                                            increased brand awareness, customer trust, and,
                                                            ultimately, sales and conversions.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/> Kickstart
                                                            Growth: Buying followers can kickstart your Instagram
                                                            growth, providing a solid foundation you can continue to
                                                            build organically over time.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How Many Followers Can You Buy At Once?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">With IG
                                                            Champ, you can purchase up to 25,000 followers at once.
                                                            This allows you to quickly and effectively boost your
                                                            Instagram presence with a significant number of
                                                            followers, helping you increase your visibility and
                                                            credibility on the platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I Buy Instagram Followers for My Business?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Absolutely!
                                                            Buying Instagram followers for your business can be a
                                                            strategic move to enhance your brand's visibility,
                                                            credibility, and reach. A larger follower count can
                                                            attract more attention to your business profile, making
                                                            it appear more reputable and trustworthy to potential
                                                            customers. Besides this, a higher follower count can
                                                            increase engagement with your content, ultimately
                                                            driving more traffic to your website, generating leads,
                                                            and increasing sales.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">However,
                                                            it's essential to ensure that you're purchasing
                                                            followers from a reputable provider like IG Champ to
                                                            guarantee high-quality, real followers who authentically
                                                            engage with your content. Additionally, buying followers
                                                            should be just one part of your overall Instagram
                                                            marketing strategy, including creating compelling
                                                            content, engaging with your audience, and using other
                                                            growth tactics to build a genuine and active community
                                                            around your brand.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Could My Account Be Banned From Buying Instagram Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Buying
                                                            Instagram followers is not against Instagram's terms of
                                                            service to buy followers, but it's important to be
                                                            cautious and use reputable providers like IG Champ.
                                                            Instagram has algorithms to detect and remove fake or
                                                            bot accounts. So, if you purchase followers from
                                                            low-quality or unethical sources, there's a risk that
                                                            those followers could be detected and removed,
                                                            potentially resulting in penalties for your account.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">To minimize
                                                            the risk of your account being banned or penalized,
                                                            buying followers from a trusted provider that delivers
                                                            high-quality, real followers who engage with your
                                                            content authentically is better. IG Champ prioritizes
                                                            authenticity and transparency, ensuring that all
                                                            followers provided are genuine and compliant with
                                                            Instagram's guidelines.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">It's also
                                                            important to complement your follower growth strategy
                                                            with other organic growth tactics, such as creating
                                                            high-quality content, engaging with your audience, and
                                                            participating in community activities on the platform.
                                                            By taking a balanced approach to growing your Instagram
                                                            presence, you can mitigate the risk of penalties and
                                                            ensure long-term success for your account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Will Anyone Know I Have Purchased Followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">When you
                                                            purchase followers from a reputable provider like IG
                                                            Champ, it's unlikely that anyone will know you've bought
                                                            followers unless you explicitly disclose it.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">However, if
                                                            you buy followers from low-quality or unethical sources,
                                                            it may become apparent to others. Fake followers
                                                            typically lack engagement, have incomplete profiles, or
                                                            exhibit suspicious behavior, which can be easily
                                                            identified by savvy Instagram users or by Instagram's
                                                            algorithms. This could potentially damage your
                                                            reputation and credibility on the platform.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">To ensure
                                                            that your purchased followers blend seamlessly with your
                                                            organic following and appear genuine, it is crucial to
                                                            choose a trusted provider that delivers high-quality,
                                                            real followers who engage with your content
                                                            authentically. By investing in authentic growth, you can
                                                            maintain the integrity of your account and avoid any
                                                            negative consequences associated with buying followers
                                                            from unreliable sources.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h2 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How Fast Will I See Results?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h2>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Once you
                                                            hit that purchase button with IG Champ, your Instagram
                                                            presence is set to skyrocket! From the moment your order
                                                            is confirmed, our team kicks into high gear, working
                                                            tirelessly behind the scenes to increase your follower
                                                            count. Within hours, your follower numbers will grow,
                                                            transforming your profile with real activity and
                                                            engagement. With IG Champ, the results are not just
                                                            fast; they're electrifying!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}
