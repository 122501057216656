import React, {useState} from 'react'
import {Link} from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, BsCheckCircle, FaArrowDown} from "../../assets/icons/icons"
import {accordionData, pricingTableData} from '../../data/dataTwo';
import shree_logo from "../../assets/images/igchamp/select-package.png";
import google_logo from "../../assets/images/igchamp/put-info.png";
import lenovo_logo from "../../assets/images/igchamp/payment.png";
import circle_logo from "../../assets/images/igchamp/deliver-order.png";
import classic02 from "../../assets/images/igchamp/buy-instagram-likes.png";
import {Helmet} from "react-helmet";

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pricingTable, setpricingTable] = useState(pricingTableData.filter((element) => {
        return element.cat === 11;
    }))
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    return (
        <>
            <Helmet>
                <title>Buy instagram Likes - Real and Instant - Starting from $1</title>
                <meta name="description" content="Get real Instagram likes from IGChamp from just $1. Real and Instant likes, best place to buy real likes with Instant delivery ."/>
                <meta property="og:title" content="Buy instagram Likes - Real and Instant - Starting from $1"/>
                <meta property="og:description" content="Get real Instagram likes from IGChamp from just $1. Real and Instant likes, best place to buy real likes with Instant delivery ."/>
                <meta name="keywords" content="Buy Instagram Likes"/>
                <meta property="og:image" content={classic02}/>
                <meta property="og:url" content="/buy-instagram-likes"/>
                <link rel="canonical" href="https://igchamp.com/buy-instagram-likes"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="text-4xl leading-normal font-semibold">Buy Instagram Likes to Maximize Your
                            Influence</h1>
                        <p>Buy real Instagram likes from IG Champ to effortlessly maximize your influence. With our
                            service, you can boost engagement, increase visibility, and stand out on Instagram. Elevate
                            your profile today and start making an impact.</p>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/">Home</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight
                            className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600">
                            <Link>Buy Instagram Likes</Link></li>

                    </ul>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            Pricing
                            Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Your account should be public!</p>
                    </div>
                    <div id="StarterContent" className="mt-6">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                            {pricingTable.map((pricing, index) => {
                                return (
                                    <div key={index}
                                        className="group border-b-[3px] dark:border-gray-700 p-6 py-8 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow dark:shadow-gray-800 rounded-md bg-gray-50 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-900 h-fit">
                                        <h6 className="font-bold uppercase mb-5 text-indigo-600">{pricing.name}</h6>

                                        <div className="flex mb-5">
                                            <span className="text-xl font-semibold">$</span>
                                            <span className="price text-4xl font-semibold mb-0">{pricing.price}</span>
                                            <span className="text-xl font-semibold self-end mb-1"></span>
                                        </div>

                                        <ul className="list-none text-slate-400">
                                            <li className="mb-1 flex items-center" key={"a" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> 5 Minutes to
                                                24Hours
                                            </li>
                                            <li className="mb-1 flex items-center" key={"b" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Safe &
                                                Secure
                                            </li>
                                            <li className="mb-1 flex items-center" key={"c" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Real and
                                                Active
                                            </li>
                                            <li className="mb-1 flex items-center" key={"d" + pricing.id}>
                                                <BsCheckCircle className="text-indigo-600 text-base me-2"/> Money-Back
                                                Guarantee
                                            </li>
                                        </ul>
                                        <a
                                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
                                            href={pricing.link} rel="nofollow">Buy
                                            Now</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden pb-32 bg-gray-50 dark:bg-slate-800">
                <div className="container grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img src={classic02} className="rounded-lg dark:shadow-gray-800 mx-auto"
                             alt="Buy Instagram likes"/>
                        <div
                            className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>
                    <div className="lg:ms-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                        <h2 className="mb-4 text-2xl leading-normal font-medium">Buy Instagram Likes for Visible
                            Growth</h2>
                        <p className="text-slate-400">Boost your Instagram presence with IG Champ by purchasing
                            Instagram likes for visible growth. Our carefully curated packages ensure only real and
                            active accounts engage with your accounts. With our services, we ensure to enhance your
                            profile's credibility. As your likes increase, so does your visibility, attracting even more
                            organic engagement. Choose IG Champ to take the first step towards a stronger online
                            presence and watch your influence grow effortlessly.</p>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose
                            a Package in Your Budget
                        </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">At IG Champ, we offer a variety of
                            budget-friendly packages designed to meet your specific needs. Whether you're a small
                            business or an influencer, you can find the perfect plan to kickstart your journey to
                            Instagram success. Start now and witness the growth of your presence.</p>
                    </div>

                    <div className="grid grid-cols-1 mt-8">
                        <div
                            className="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                            <div
                                className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={shree_logo} className="md:ms-auto" alt=""/>
                                    </div>
                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Select Package</h5>
                                        <p className="mt-3 mb-0 text-slate-400">We offer a diverse range of packages to
                                            meet your needs and budget. Select the package that best suits you and take
                                            the first step towards buying real Instagram likes. Each package delivers
                                            engaged activity on your account that aligns with your expectations and
                                            goals. With IG Champ this is just the beginning of having your social media
                                            impact. </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={google_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Put Desired Info</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Next, you are expected to fill in the
                                            necessary details to help us move forward efficiently. You will be asked to
                                            provide your Instagram handle and any specific preferences that you have.
                                            Since your privacy is our priority, we won’t ask for your passwords or any
                                            sensitive data. </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="md:text-end md:me-8 relative">
                                        <img src={lenovo_logo} className="md:ms-auto" alt=""/>
                                    </div>

                                    <div className="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                        <h5 className="title mb-1 font-semibold">Pay for your order</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Once you’ve given us all that we need,
                                            you’re expected to make the payment. You can securely complete your purchase
                                            with our trusted payment options. We prioritize your convenience and
                                            security, so we offer different payment methods to suit your needs. This
                                            allows you to confidently buy real Instagram likes with transparent pricing
                                            and no hidden fees.</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                                <div className="grid md:grid-cols-2">
                                    <div className="text-start ms-8 relative md:order-2">
                                        <img src={circle_logo} className="md:me-auto" alt=""/>
                                    </div>

                                    <div
                                        className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                        <h5 className="title mb-1 font-semibold">Order Delivered</h5>
                                        <p className="mt-3 mb-0 text-slate-400">Lastly, all you need to do is sit back
                                            and review the engagement of your content. Our professional and committed
                                            service will ensure quick results, boosting your visibility and attracting
                                            your audience. When you have any questions, we are always here to address
                                            them. Remember, at IG Champ, your satisfaction is always guaranteed
                                            throughout the process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h2 className="text-2xl font-semibold mb-4">Buy Instagram Likes for Visible Growth</h2>
                                <p className="text-slate-400">Boost your Instagram presence with IG Champ by <b
                                    className="text-red-600">purchasing Instagram likes</b> for visible growth. Our
                                    carefully curated packages ensure only real and active accounts engage with your
                                    accounts. With our services, we ensure to enhance your profile's credibility. As
                                    your likes increase, so does your visibility, attracting even more organic
                                    engagement. Choose IG Champ to take the first step towards a stronger online
                                    presence and watch your influence grow effortlessly.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">100% Confidentiality</h5>
                                <p className="text-slate-400">At <Link to='/'><b className="text-red-600">IG
                                    Champ</b></Link>, we prioritize
                                    your privacy with 100% confidentiality. Our services ensure your personal
                                    information and account details remain secure and undisclosed. Therefore, we use
                                    advanced security measures to protect your data throughout the entire process. Trust
                                    IG Champ to deliver exceptional results while safeguarding your confidentiality.
                                    This will surely result in your peace of mind as you boost your Instagram presence.
                                </p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">Permanent Likes</h5>
                                <p className="text-slate-400">With IG Champ, you don’t have to worry about temporary
                                    likes. In fact, you can benefit from permanent likes that enhance your Instagram
                                    presence long-term. Our packages provide real, engaged followers, ensuring the likes
                                    you receive are genuine and lasting. Unlike temporary boosts, these permanent likes
                                    contribute to sustained visibility and credibility. You can always put your trust in
                                    IG Champ to <b className="text-red-600"> buy likes on Instagram</b> for reliable
                                    growth that endures. Eventually, your Instagram profile will be stronger and more
                                    influential over time.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Instagram Compliant</h5>
                                <p className="text-slate-400">When <Link to='/buy-instagram-likes'><b
                                    className="text-red-600">buying Instagram likes from IG Champ</b></Link>, you don’t
                                    have to worry about getting your account banned. We ensure that all our services are
                                    fully Instagram compliant. Therefore, we strictly adhere to Instagram's guidelines
                                    and policies, providing authentic and organic likes. Our methods are safe and
                                    secure, avoiding any risk of penalties or account suspensions. With IG Champ, you
                                    can confidently enhance your Instagram presence while following rules. This will
                                    ensure long-term success and credibility for you and a satisfied client for us.</p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Economical Packages</h5>
                                <p className="text-slate-400">IG Champ offers economical packages designed to fit any
                                    budget. Our diverse range of affordable options allows you to choose the plan that
                                    best suits your needs, goals, and pockets. Despite the cost-effectiveness, each
                                    package delivers real, engaged accounts, ensuring maximum value for your investment.
                                    With IG Champ, you can achieve significant growth on Instagram without breaking the
                                    bank. So, this will also make it easier than ever to boost your online presence and
                                    reach your desired audience.
                                </p>
                                <h5 className="text-xl font-semibold mb-4 mt-8">Return on Investment (ROI)</h5>
                                <p className="text-slate-400">You always require a reliable company to meet your social
                                    media needs. If you work with a lousy one, they will tarnish your brand’s image. But
                                    IG Champ is not that. We have been in the business for a long and are all aware of
                                    the ins and outs. With the professionals onboard, we provide the real likes that
                                    help you reach your target audience more frequently. This translates to more sales
                                    and return on investment (ROI). So, with IG Champ you can rest assured about
                                    achieving your marketing goals. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-6">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <div className="md:mt-24 mt-16">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Got
                                            Questions? Get the Answers</h3>
                                    </div>

                                    <div id="accordion-collapse" data-accordion="collapse"
                                         className="grid md:grid-cols-2 grid-cols-1 mt-8 md:gap-[30px]">
                                        <div>
                                            <div key="11"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(11)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 11 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How can I always get more likes on Instagram?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 11 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 11 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Consistency is
                                                            key to increasing likes on Instagram. Post high-quality
                                                            content regularly to keep your audience engaged. You can
                                                            also use relevant hashtags to reach a wider audience and
                                                            encourage interaction by asking questions or running
                                                            contests. Also, engage with your followers by responding to
                                                            comments and messages promptly.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">You shall also
                                                            collaborate with influencers or participate in Instagram
                                                            pods to expand your reach. Additionally, consider promoting
                                                            your posts or using Instagram's features like Stories and
                                                            Reels to attract more attention. By implementing these
                                                            strategies consistently, you can steadily increase likes on
                                                            your Instagram posts.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="12"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(12)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 12 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why choose IG Champ to buy Instagram likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 12 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 12 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Choosing IG
                                                            Champ to buy Instagram likes offers several benefits.
                                                            Firstly, we work with real and engaging profiles to like
                                                            your posts. This helps maintain credibility and authenticity
                                                            for your profile. Likewise, our services are also secure and
                                                            confidential which safeguards your privacy throughout the
                                                            process. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Moreover, we
                                                            offer a range of economical packages, making it accessible
                                                            for anyone to enhance their Instagram presence. With IG
                                                            Champ, you can expect reliable growth and excellent customer
                                                            service. All of this makes us the ideal choice for boosting
                                                            your Instagram likes.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="13"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(13)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 13 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can Instagram likes help me gain more followers?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 13 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 13 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Of course!
                                                            Instagram likes play a crucial role in attracting more
                                                            followers. When your posts receive a high number of likes,
                                                            it signals to the Instagram algorithm that your content is
                                                            engaging and valuable. As a result, your posts are more
                                                            likely to be shown to a wider audience through the Explore
                                                            page and hashtags. This results in your account’s increased
                                                            visibility. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Additionally,
                                                            users are more inclined to follow accounts that already have
                                                            a significant number of likes, as it indicates credibility
                                                            and popularity. Therefore, by consistently receiving likes
                                                            on your posts, you can attract more followers to your
                                                            Instagram account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="14"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(14)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 14 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>When do I need to buy Instagram likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 14 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 14 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400"><b
                                                            className="text-red-600">Buying Instagram likes</b> should
                                                            be approached cautiously and strategically. However, there
                                                            are certain situations where purchasing likes may be
                                                            considered:</p>
                                                    </div>
                                                    <ul className="list-none space-x-1 text-slate-400 mt-3">
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Jumpstarting
                                                            a New Account: When starting a new Instagram account, it can
                                                            be challenging to gain traction and visibility initially.
                                                            Buying likes can help give your profile a boost in
                                                            visibility and credibility, making it more attractive to
                                                            potential followers.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Increasing
                                                            Visibility for Important Posts: If you have a particularly
                                                            important or high-value post that you want to ensure gets
                                                            noticed by a wider audience, buying likes can help increase
                                                            its visibility on users' feeds and explore pages.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Promoting
                                                            Products or Services: If you're running a promotional
                                                            campaign or launching a new product or service. In this
                                                            case, buying likes can help increase the reach and impact of
                                                            your marketing efforts. This will also potentially lead to
                                                            more conversions and sales.
                                                        </li>
                                                        <li className="flex items-center mt-2"><FaArrowRight
                                                            className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Boosting
                                                            Social Proof: Having a high like count can serve as social
                                                            proof of your popularity and credibility. This can be
                                                            especially important for influencers, brands, and businesses
                                                            looking to attract partnerships or collaborations.
                                                        </li>
                                                    </ul>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">However, it is
                                                            essential to use bought likes strategically and in
                                                            conjunction with other organic growth strategies.
                                                            Over-reliance on purchased likes can ultimately harm your
                                                            authenticity and long-term success on the platform. Focus on
                                                            creating high-quality content, engaging with your audience
                                                            authentically, and building genuine connections to grow your
                                                            Instagram presence sustainably.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div key="15"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(15)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 15 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Do I need a public Instagram profile for buying likes on Insta?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 15 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 15 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Yes, you would
                                                            typically need a public Instagram profile to buy likes on
                                                            the platform. IG Champ usually requires access to public
                                                            posts so we can deliver the likes effectively. Additionally,
                                                            having a public profile ensures that the likes you purchase
                                                            are visible to all users. This contributes to your overall
                                                            visibility and credibility on the platform.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">If your
                                                            Instagram profile is set to private, it limits the reach and
                                                            impact of the likes you purchase, as only approved followers
                                                            can see your posts. Therefore, if you're considering buying
                                                            likes, it's advisable to set your profile to public to
                                                            maximize the benefits of this strategy. However, always
                                                            ensure to weigh the pros and cons of buying likes and
                                                            consider the potential impact on your account's reputation
                                                            and authenticity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="16"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(16)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 16 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Why should I buy Instagram likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 16 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 16 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">While organic
                                                            growth is valuable, purchasing Instagram likes can
                                                            strategically boost your visibility and credibility on the
                                                            platform. Buying likes can help jumpstart your account's
                                                            growth by increasing your posts' reach and attracting more
                                                            attention from potential followers. Besides this, it can
                                                            also give your profile a polished and professional
                                                            appearance. This will make your profile more appealing to
                                                            brands and businesses looking for influencers to collaborate
                                                            with. </p>
                                                        <p className="text-slate-400 dark:text-gray-400">Furthermore, a
                                                            higher like count can help you stand out in crowded feeds
                                                            and attract more organic engagement. Just be sure to
                                                            complement this strategy with high-quality content and
                                                            genuine interactions. All of this will help maintain
                                                            authenticity and build a loyal following over time.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="17"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(17)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 17 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>How do I know how many likes I need per post?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 17 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 17 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Determining the
                                                            ideal number of likes per post on Instagram can vary. It
                                                            depends on several factors, including your goals, target
                                                            audience, and industry. One approach is to analyze the
                                                            engagement metrics of your past posts to identify patterns
                                                            and trends. You shall look for posts that received high
                                                            levels of engagement in terms of likes, comments, and
                                                            shares, and replicate those elements in future content.</p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Additionally,
                                                            consider benchmarking against competitors or similar
                                                            accounts within your niche. This will help you understand
                                                            what's considered typical or successful in your industry.
                                                            Ultimately, the right number of likes per post is one that
                                                            helps you achieve your objectives. Whether it's increasing
                                                            brand awareness, driving website traffic, or generating
                                                            sales leads. Plus, experimentation is always the key; you
                                                            can also perform an ongoing analysis to refine your strategy
                                                            to gradually optimize your Instagram presence.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="18"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(18)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 18 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What’s the quality of real likes?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 18 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 18 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Real likes on
                                                            Instagram come from genuine users who are genuinely
                                                            interested in your content. These likes reflect authentic
                                                            engagement. Often these are more valuable than artificially
                                                            inflated numbers obtained through buying likes or engagement
                                                            pods. Real likes indicate that your content resonates with
                                                            your audience, leading to increased visibility and higher
                                                            credibility. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">In fact, it
                                                            also opens more opportunities for growth and collaboration.
                                                            They also contribute to building a loyal and engaged
                                                            community around your profile, fostering meaningful
                                                            connections and interactions. Ultimately, the quality of
                                                            real likes lies in their authenticity and their ability to
                                                            drive genuine engagement and support for your content and
                                                            brand.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="19"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(19)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 19 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>Can I split my bought likes amongst posts?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 19 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 19 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Some services
                                                            may offer the option to split purchased likes among multiple
                                                            posts. However, it’s better to consider the potential
                                                            consequences and effectiveness of such an approach.
                                                            Splitting bought likes across posts can dilute the impact
                                                            and authenticity of engagement on each individual post. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">This is because
                                                            the likes won't be organically generated by users genuinely
                                                            interested in each piece of content. Additionally,
                                                            artificially inflating the like count on multiple posts
                                                            simultaneously may raise suspicions among your audience.
                                                            This will result in potential harm your credibility and
                                                            reputation on the platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div key="20"
                                                 className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                                <h4 className="text-base font-semibold"
                                                    id="accordion-collapse-heading-1">
                                                    <button type="button" onClick={() => toggleAccordion(20)}
                                                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === 20 ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`}
                                                            data-accordion-target="#accordion-collapse-body-1"
                                                            aria-expanded="true"
                                                            aria-controls="accordion-collapse-body-1">
                                                        <span>What kind of content gets more likes on Instagram?</span>
                                                        <FaArrowDown
                                                            className={`${activeIndex === 20 ? "rotate-180" : "rotate-270"} w-4 h-4 shrink-01`}></FaArrowDown>
                                                    </button>
                                                </h4>
                                                <div className={activeIndex === 20 ? "" : "hidden"}>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Visually
                                                            appealing, relevant, and engaging content tends to receive
                                                            more likes on Instagram. This includes high-quality photos
                                                            and videos that capture attention, evoke emotions, or tell a
                                                            compelling story. Additionally, authentic, relatable content
                                                            that reflects your personality or brand identity resonates
                                                            well with audiences. Using trending hashtags, posting at
                                                            optimal times, and engaging with your followers also
                                                            contribute to increased likes. </p>
                                                    </div>
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">Experimenting
                                                            with different content formats, such as carousels, reels,
                                                            and IGTV, can also help diversify your content and attract
                                                            more likes. Understanding your audience's preferences and
                                                            interests is key to creating content that consistently
                                                            receives more likes on Instagram. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
